






















import { Component, Vue, Prop } from 'vue-property-decorator';
import ApexChart from 'vue-apexcharts';
import { chartTimeLineValues } from '@/types/metric';
import IntervalButtons from '@/components/visualization-metric/IntervalButtons.vue';
import { ITimestamp } from '@/types/common';
import { store } from '@/store';

@Component({ name: 'LineChartIntervals', components: { IntervalButtons, ApexChart } })
export default class LineChartIntervals extends Vue {
  @Prop() private readonly metricId!: number;
  @Prop() private readonly data!: chartTimeLineValues
  @Prop() private readonly videoDuration!: number;

  private heightBlock = '';

  get series() {
    return [{ data: this.getSeries }];
  }

  get chartOptions() {
    return {
      chart: {
        type: 'rangeBar',
        background: 'transparent',
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            const indexTarget = config.dataPointIndex;
            this.setInterval(this.intervalButtons[indexTarget]);
          },
        },
      },
      theme: { mode: 'dark' },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      legend: {
        position: 'left',
      },
      xaxis: {
        max: this.videoDuration,
        axisBorder: { color: '#555' },
        labels: {
          formatter: (val: number) => this.$secondsToTime(val),
        },
      },
      grid: {
        borderColor: '#555',
        xaxis: { lines: { show: false } },
      },
      fill: { opacity: 0.7 },
      tooltip: {
        fillSeriesColor: false,
        x: {
          formatter: (value: any) => {
            if (typeof value === 'number') return this.$secondsToTime(value);
            return value;
          },
        },
      },
    };
  }

  get height(): number {
    const defaultHeight = 400;
    const rows = this.data.length;
    if (rows > 16) return defaultHeight + (rows - 16) * 26;
    return defaultHeight;
  }

  get intervalButtons(): Array<ITimestamp> {
    const buttons: Array<ITimestamp> = [];
    this.data.forEach(({ time, value: name }) => {
      const color = this.$getStudentColor(name, 'HEX') as string;
      time.forEach(({ from, to }) => {
        const too = to > this.videoDuration ? this.videoDuration : to; // TODO убрать условие после фикса неккоректных данных
        buttons.push({
          color,
          from,
          to: too, // TODO убрать условие после фикса неккоректных данных
          tooltip: name,
          label: `${this.$secondsToTime(from)} - ${this.$secondsToTime(too)}`, // TODO убрать условие после фикса неккоректных данных
        });
      });
    });
    return buttons;
  }

  get getSeries() {
    if (!this.data) return;
    const series: any = [];
    this.data.forEach(({ time, value: name }) => {
      const color = this.$getStudentColor(name, 'HEX');
      time.forEach(({ from, to }) => {
        const too = to > this.videoDuration ? this.videoDuration : to; // TODO убрать условие после фикса неккоректных данных
        series.push({
          fillColor: color,
          x: name,
          y: [from, too], // TODO убрать условие после фикса неккоректных данных
        });
      });
    });
    return series;
  }

  setInterval(interval: ITimestamp) {
    store.player.mutations.saveIntervals({ merticId: this.metricId, intervals: [interval] });
    this.$emit('rewind-to-interval', interval);
    this.$emit('change-intervals');
  }

  mounted() {
    this.$nextTick(() => {
      const block = document.getElementById('timeline-chart');
      this.heightBlock = String(block?.getBoundingClientRect().height);
    });
  }
}
