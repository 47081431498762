




















import { Component, Vue, Prop } from 'vue-property-decorator';
import { ITimestamp } from '@/types/common';

@Component({ name: 'IntervalButtons' })
export default class IntervalButtons extends Vue {
  @Prop() protected readonly intrevals!: Array<ITimestamp>;
}
